// src/components/weather/WeatherOverview.jsx
import React from "react";
import { useWeather } from "../../context/WeatherContext";
import "./WeatherOverview.css";

const WeatherOverview = () => {
  const { currentWeather, unit, convertTemperature, toggleUnit } = useWeather();

  if (!currentWeather) return null;

  const {
    main: { temp, feels_like, humidity },
    wind: { speed },
    weather: [{ description, icon }],
    name: cityName,
    dt,
  } = currentWeather;

  const date = new Date(dt * 1000);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <div className="weather-overview">
      <div className="weather-overview__header">
        <h2>{cityName}</h2>
        <p>{formattedDate}</p>
      </div>

      <div className="weather-overview__main">
        <img
          src={`http://openweathermap.org/img/wn/${icon}@2x.png`}
          alt={description}
          className="weather-icon"
        />

        <div className="temperature-container">
          <h1>
            {Math.round(convertTemperature(temp))}°
            <span className="unit-toggle" onClick={toggleUnit}>
              {unit === "celsius" ? "C" : "F"}
            </span>
          </h1>
          <p className="weather-description">
            {description.charAt(0).toUpperCase() + description.slice(1)}
          </p>
        </div>
      </div>

      <div className="weather-overview__details">
        <div className="detail-item">
          <span className="detail-label">Feels Like</span>
          <span className="detail-value">
            {Math.round(convertTemperature(feels_like))}°
          </span>
        </div>

        <div className="detail-item">
          <span className="detail-label">Wind</span>
          <span className="detail-value">{Math.round(speed)} m/s</span>
        </div>

        <div className="detail-item">
          <span className="detail-label">Humidity</span>
          <span className="detail-value">{humidity}%</span>
        </div>
      </div>
    </div>
  );
};

export default WeatherOverview;
