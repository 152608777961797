// src/components/weather/SunMoonInfo.jsx
import React, { useState, useEffect } from "react";
import { useWeather } from "../../context/WeatherContext";
import "./SunMoonInfo.css";

const SunMoonInfo = () => {
  const { currentWeather } = useWeather();
  const [activeTab, setActiveTab] = useState("sun");
  const [sunPosition, setSunPosition] = useState(0);
  const [dayLength, setDayLength] = useState("");
  const [currentPeriod, setCurrentPeriod] = useState("");
  const [remainingTime, setRemainingTime] = useState("");

  useEffect(() => {
    if (!currentWeather) return;

    const updateSunInfo = () => {
      const now = new Date().getTime() / 1000;
      const sunrise = currentWeather.sys.sunrise;
      const sunset = currentWeather.sys.sunset;
      const dayDuration = sunset - sunrise;

      // Calculate sun position percentage
      if (now >= sunrise && now <= sunset) {
        const elapsed = now - sunrise;
        setSunPosition((elapsed / dayDuration) * 100);
        setCurrentPeriod("Day");
        setRemainingTime(formatRemainingTime(sunset - now));
      } else {
        setSunPosition(now < sunrise ? 0 : 100);
        setCurrentPeriod("Night");
        setRemainingTime(
          formatRemainingTime(
            now < sunrise ? sunrise - now : sunrise + 86400 - now
          )
        );
      }

      // Calculate day length
      const hours = Math.floor(dayDuration / 3600);
      const minutes = Math.floor((dayDuration % 3600) / 60);
      setDayLength(`${hours}h ${minutes}m`);
    };

    updateSunInfo();
    const interval = setInterval(updateSunInfo, 60000);
    return () => clearInterval(interval);
  }, [currentWeather]);

  const formatTime = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatRemainingTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  const getMoonPhaseName = (phase) => {
    if (phase <= 0.125) return "New Moon";
    if (phase <= 0.25) return "Waxing Crescent";
    if (phase <= 0.375) return "First Quarter";
    if (phase <= 0.625) return "Full Moon";
    if (phase <= 0.75) return "Last Quarter";
    if (phase <= 0.875) return "Waning Crescent";
    return "New Moon";
  };

  if (!currentWeather) return null;

  const getUVIndexDescription = (index) => {
    if (index <= 2) return { level: "Low", color: "#558B2F" };
    if (index <= 5) return { level: "Moderate", color: "#F9A825" };
    if (index <= 7) return { level: "High", color: "#EF6C00" };
    if (index <= 10) return { level: "Very High", color: "#B71C1C" };
    return { level: "Extreme", color: "#6A1B9A" };
  };

  const uvInfo = getUVIndexDescription(currentWeather.uvIndex || 0);

  return (
    <div className="sun-moon-info">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "sun" ? "active" : ""}`}
          onClick={() => setActiveTab("sun")}
        >
          <span className="tab-icon">☀️</span>
          <span className="tab-text">Sun</span>
        </button>
        <button
          className={`tab ${activeTab === "moon" ? "active" : ""}`}
          onClick={() => setActiveTab("moon")}
        >
          <span className="tab-icon">🌙</span>
          <span className="tab-text">Moon</span>
        </button>
      </div>

      <div className="content">
        {activeTab === "sun" ? (
          <div className="sun-content">
            {/* Sun Arc Visualization */}
            <div className="sun-arc-container">
              <div className="sun-arc">
                <div
                  className="sun-indicator"
                  style={{ left: `${sunPosition}%` }}
                />
                <div className="time-markers">
                  <span>Dawn</span>
                  <span>Noon</span>
                  <span>Dusk</span>
                </div>
              </div>
            </div>

            {/* Sun Information */}
            <div className="sun-info-grid">
              <div className="sun-info-primary">
                <div className="current-period">
                  <span className="period-label">{currentPeriod}</span>
                  <span className="remaining-time">
                    {remainingTime} remaining
                  </span>
                </div>
                <div className="day-length">
                  <span className="length-label">Day Length</span>
                  <span className="length-value">{dayLength}</span>
                </div>
              </div>

              <div className="sun-time-details">
                <div className="info-item">
                  <div className="icon sunrise-icon" />
                  <div>
                    <span className="label">Sunrise: </span>
                    <span className="value">
                      {formatTime(currentWeather.sys.sunrise)}
                    </span>
                  </div>
                </div>
                <div className="info-item">
                  <div className="icon sunset-icon" />
                  <div>
                    <span className="label">Sunset: </span>
                    <span className="value">
                      {formatTime(currentWeather.sys.sunset)}
                    </span>
                  </div>
                </div>
              </div>

              {/* UV Index Display */}
              <div className="uv-index-container">
                <div className="uv-index-header">
                  <span>UV Index</span>
                  <span className="uv-value" style={{ color: uvInfo.color }}>
                    {currentWeather.uvIndex || 0}
                  </span>
                </div>
                <div className="uv-index-bar">
                  <div
                    className="uv-index-indicator"
                    style={{
                      left: `${((currentWeather.uvIndex || 0) / 11) * 100}%`,
                      backgroundColor: uvInfo.color,
                    }}
                  />
                </div>
                <span className="uv-level" style={{ color: uvInfo.color }}>
                  {uvInfo.level}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="moon-content">
            <div className="moon-phase-visual">
              <div className="moon-display">
                <div className="moon-circle">
                  <div
                    className="moon-phase-indicator"
                    style={{
                      transform: `rotate(${currentWeather.moonPhase * 360}deg)`,
                    }}
                  />
                  <div className="moon-crater crater-1"></div>
                  <div className="moon-crater crater-2"></div>
                  <div className="moon-crater crater-3"></div>
                </div>
                <div className="moon-shadow"></div>
              </div>

              {/* Moon Phase Progress */}
              <div className="moon-phase-progress">
                <div className="progress-points">
                  <div
                    className={`point ${
                      currentWeather.moonPhase <= 0.125 ? "active" : ""
                    }`}
                  >
                    <span className="point-label">New</span>
                  </div>
                  <div
                    className={`point ${
                      currentWeather.moonPhase > 0.125 &&
                      currentWeather.moonPhase <= 0.25
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="point-label">Waxing Crescent</span>
                  </div>
                  <div
                    className={`point ${
                      currentWeather.moonPhase > 0.25 &&
                      currentWeather.moonPhase <= 0.5
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="point-label">First Quarter</span>
                  </div>
                  <div
                    className={`point ${
                      currentWeather.moonPhase > 0.5 &&
                      currentWeather.moonPhase <= 0.75
                        ? "active"
                        : ""
                    }`}
                  >
                    <span className="point-label">Full</span>
                  </div>
                  <div
                    className={`point ${
                      currentWeather.moonPhase > 0.75 ? "active" : ""
                    }`}
                  >
                    <span className="point-label">Last Quarter</span>
                  </div>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress-fill"
                    style={{ width: `${currentWeather.moonPhase * 100}%` }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="moon-details">
              <div className="moon-info-card">
                <div className="info-header">Current Phase</div>
                <div className="info-value">
                  {getMoonPhaseName(currentWeather.moonPhase)}
                </div>
                <div className="info-subtext">
                  {Math.round(currentWeather.moonPhase * 100)}% illuminated
                </div>
              </div>

              <div className="moon-info-grid">
                <div className="moon-time-item">
                  <span className="moon-time-icon">🌅</span>
                  <div className="moon-time-details">
                    <span className="label">Moonrise</span>
                    <span className="value">
                      {formatTime(currentWeather.sys.sunrise)}
                    </span>
                  </div>
                </div>

                <div className="moon-time-item">
                  <span className="moon-time-icon">🌄</span>
                  <div className="moon-time-details">
                    <span className="label">Moonset</span>
                    <span className="value">
                      {formatTime(currentWeather.sys.sunset)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SunMoonInfo;
