// src/context/WeatherContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { weatherService } from "../services/weatherService";

const WeatherContext = createContext();

export const WeatherProvider = ({ children }) => {
  const [currentWeather, setCurrentWeather] = useState(null);
  const [forecast, setForecast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [unit, setUnit] = useState("celsius");

  const convertTemperature = (celsius) => {
    if (unit === "fahrenheit") {
      return (celsius * 9) / 5 + 32;
    }
    return celsius;
  };

  const toggleUnit = () => {
    setUnit((prev) => (prev === "celsius" ? "fahrenheit" : "celsius"));
  };

  const searchCity = async (query) => {
    try {
      const results = await weatherService.searchCity(query);
      return results;
    } catch (err) {
      console.error("Error searching city:", err);
      throw err;
    }
  };

  const fetchWeatherData = async (lat, lon) => {
    console.log("Fetching weather data for coordinates:", { lat, lon });
    try {
      const [weatherResponse, forecastResponse] = await Promise.all([
        weatherService.getCurrentWeather(lat, lon),
        weatherService.getForecast(lat, lon),
      ]);

      setCurrentWeather({
        ...weatherResponse,
        uvIndex: weatherService.calculateUVIndex(lat, lon, weatherResponse.dt),
        moonPhase: weatherService.calculateMoonPhase(new Date()),
      });
      setForecast(forecastResponse);
      setError(null);
    } catch (err) {
      console.error("Error fetching weather data:", err);
      setError("Failed to fetch weather data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getInitialWeather = async () => {
      try {
        const defaultCoords = { lat: 51.5074, lon: -0.1278 };

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              fetchWeatherData(
                position.coords.latitude,
                position.coords.longitude
              );
            },
            (error) => {
              console.log("Geolocation error, using default location:", error);
              fetchWeatherData(defaultCoords.lat, defaultCoords.lon);
            }
          );
        } else {
          fetchWeatherData(defaultCoords.lat, defaultCoords.lon);
        }
      } catch (err) {
        console.error("Error in getInitialWeather:", err);
        setError("Failed to initialize weather data");
        setLoading(false);
      }
    };

    getInitialWeather();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      fetchWeatherData(selectedCity.lat, selectedCity.lon);
    }
  }, [selectedCity]);

  const value = {
    currentWeather,
    forecast,
    loading,
    error,
    selectedCity,
    setSelectedCity,
    unit,
    convertTemperature,
    toggleUnit,
    searchCity, // Add searchCity to context
    fetchWeatherData,
  };

  return (
    <WeatherContext.Provider value={value}>{children}</WeatherContext.Provider>
  );
};

export const useWeather = () => {
  const context = useContext(WeatherContext);
  if (!context) {
    throw new Error("useWeather must be used within a WeatherProvider");
  }
  return context;
};
