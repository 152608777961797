// src/components/TawkMessenger.jsx
import { useEffect } from "react";

const TawkMessenger = () => {
  useEffect(() => {
    // Tawk.to Script
    var Tawk_API = window.Tawk_API || {};
    var Tawk_LoadStart = new Date();

    (function () {
      var s1 = document.createElement("script");
      var s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/67b479756574c6190ba580fe/1ikcfkt3l";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return null;
};

export default TawkMessenger;
