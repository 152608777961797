// src/components/weather/DailyForecast.jsx
import React from "react";
import { useWeather } from "../../context/WeatherContext";
import "./DailyForecast.css";

const DailyForecast = () => {
  const { forecast, convertTemperature } = useWeather();

  if (!forecast) return null;

  // Group forecast data by day
  const dailyData = forecast.list.reduce((acc, item) => {
    const date = new Date(item.dt * 1000).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = {
        date: item.dt,
        temps: [],
        icons: [],
        descriptions: [],
      };
    }

    acc[date].temps.push(item.main.temp);
    acc[date].icons.push(item.weather[0].icon);
    acc[date].descriptions.push(item.weather[0].description);

    return acc;
  }, {});

  // Get daily min/max temps and most frequent weather condition
  const dailyForecast = Object.values(dailyData)
    .map((day) => {
      const minTemp = Math.min(...day.temps);
      const maxTemp = Math.max(...day.temps);

      // Get most frequent weather condition
      const iconFrequency = day.icons.reduce((acc, icon) => {
        acc[icon] = (acc[icon] || 0) + 1;
        return acc;
      }, {});

      const mostFrequentIcon = Object.entries(iconFrequency).sort(
        (a, b) => b[1] - a[1]
      )[0][0];

      const descriptionFrequency = day.descriptions.reduce((acc, desc) => {
        acc[desc] = (acc[desc] || 0) + 1;
        return acc;
      }, {});

      const mostFrequentDescription = Object.entries(descriptionFrequency).sort(
        (a, b) => b[1] - a[1]
      )[0][0];

      return {
        date: day.date,
        minTemp,
        maxTemp,
        icon: mostFrequentIcon,
        description: mostFrequentDescription,
      };
    })
    .slice(0, 6); // Get next 6 days

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return {
      day: date.toLocaleDateString("en-US", { weekday: "short" }),
      date: date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
    };
  };

  return (
    <div className="daily-forecast">
      <h3>6-Day Forecast</h3>
      <div className="daily-forecast__container">
        {dailyForecast.map((day) => {
          const formattedDate = formatDate(day.date);
          return (
            <div key={day.date} className="forecast-day">
              <div className="date-info">
                <span className="day">{formattedDate.day}</span>
                <span className="date">{formattedDate.date}</span>
              </div>

              <img
                src={`http://openweathermap.org/img/wn/${day.icon}.png`}
                alt={day.description}
                className="weather-icon"
              />

              <div className="temperature-range">
                <span className="max-temp">
                  {Math.round(convertTemperature(day.maxTemp))}°
                </span>
                <span className="min-temp">
                  {Math.round(convertTemperature(day.minTemp))}°
                </span>
              </div>

              <span className="description">{day.description}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DailyForecast;
