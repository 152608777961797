// src/components/layout/Footer.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <img src="/weather-press.png" alt="Weather Press Logo" />
          <p className="footer-description">
            Your trusted source for accurate weather forecasts and updates
          </p>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">Quick Links</h4>
          <nav className="footer-nav">
            <Link to="/" className="footer-link">
              Home
            </Link>
            <Link to="/about" className="footer-link">
              About
            </Link>
            <Link to="/contact" className="footer-link">
              Contact
            </Link>
            <Link to="/terms" className="footer-link">
              Terms
            </Link>
            <Link to="/privacy" className="footer-link">
              Privacy
            </Link>
          </nav>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">Weather Data</h4>
          <div className="footer-credits">
            <p>Powered by OpenWeather</p>
            <p>Weather icons © OpenWeather</p>
            <p>background Image © FreePik</p>
          </div>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">Connect With Us</h4>
          <div className="social-links">
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              Twitter
            </a>
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              Facebook
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link"
            >
              Instagram
            </a>
          </div>
        </div>
        {/* this is to add empty space */}
        <div className="footer-section">
          <h4 className="footer-subtitle"></h4>
          <div className="footer-credits"></div>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">USA</h4>
          <div className="footer-credits">
            <p>1234 Sunset Blvd, Los Angeles, CA 90028, USA</p>
            <p>+1 (310) 555-7890</p>
            <p>info@weather-press.com</p>
          </div>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">UK</h4>
          <div className="footer-credits">
            <p>156 Oxford Street, London W1D 1BL, UK</p>
            <p>+44 20 7946 0123</p>
            <p>info@weather-press.com</p>
          </div>
        </div>
        <div className="footer-section">
          <h4 className="footer-subtitle">Australia</h4>
          <div className="footer-credits">
            <p>89 Queen Street, Melbourne, VIC 3000, Australia</p>
            <p>+61 3 9123 4567</p>
            <p>info@weather-press.com</p>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p className="copyright">
          © {currentYear} Weather Press. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
