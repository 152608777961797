// src/components/pages/Terms.jsx
import React from "react";
import "./Pages.css";

const Terms = () => {
  return (
    <div className="page-container">
      <div className="page-content">
        <h1>Terms and Conditions</h1>

        <section className="content-section">
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using Weather Press, you accept and agree to be
            bound by the terms and provision of this agreement.
          </p>
        </section>

        <section className="content-section">
          <h2>2. Use License</h2>
          <p>
            Permission is granted to temporarily access and use Weather Press
            for personal, non-commercial purposes only. This is the grant of a
            license, not a transfer of title.
          </p>
          <ul>
            <li>You must not modify or copy the materials</li>
            <li>You must not use the materials for any commercial purpose</li>
            <li>
              You must not attempt to decompile or reverse engineer any software
            </li>
          </ul>
        </section>

        <section className="content-section">
          <h2>3. Disclaimer</h2>
          <p>
            The weather information provided on Weather Press is for general
            informational purposes only. While we strive to keep the information
            up to date and correct, we make no representations or warranties of
            any kind about the completeness, accuracy, reliability, or
            availability of the information.
          </p>
        </section>

        <section className="content-section">
          <h2>4. Limitations</h2>
          <p>
            Weather Press will not be liable for any damages arising from the
            use or inability to use the materials on the website, even if we
            have been notified of the possibility of such damage.
          </p>
        </section>

        <section className="content-section">
          <h2>5. Data Usage</h2>
          <p>
            We collect and use location data to provide accurate weather
            information. This data is processed in accordance with our Privacy
            Policy.
          </p>
        </section>

        <section className="content-section">
          <h2>6. Modifications</h2>
          <p>
            Weather Press may revise these terms of service at any time without
            notice. By using this website, you are agreeing to be bound by the
            current version of these terms of service.
          </p>
        </section>

        <section className="content-section">
          <h2>7. Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in
            accordance with applicable laws, and you irrevocably submit to the
            exclusive jurisdiction of the courts in that location.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
