// src/components/weather/SearchBar.jsx
import React, { useState, useEffect, useRef } from "react";
import { useWeather } from "../../context/WeatherContext";
import "./SearchBar.css";

const SearchBar = () => {
  const { searchCity, setSelectedCity } = useWeather();
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    // Get user's location on component mount
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setSelectedCity({ lat: latitude, lon: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    }
  }, [setSelectedCity]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Debounce search
  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (query.length >= 2) {
        setIsLoading(true);
        try {
          const cities = await searchCity(query);
          setSuggestions(cities);
          setShowSuggestions(true);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
          setSuggestions([]);
        } finally {
          setIsLoading(false);
        }
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [query, searchCity]);

  const handleCitySelect = (city) => {
    setSelectedCity({
      lat: city.lat,
      lon: city.lon,
      name: city.name,
      country: city.country,
    });
    setQuery("");
    setShowSuggestions(false);
  };

  return (
    <div className="search-container" ref={searchRef}>
      <div className="search-input-container">
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for a city..."
          className="search-input"
        />
        {isLoading && <div className="loading-spinner" />}
      </div>

      {showSuggestions && suggestions.length > 0 && (
        <ul className="suggestions-list">
          {suggestions.map((city) => (
            <li
              key={`${city.lat}-${city.lon}`}
              onClick={() => handleCitySelect(city)}
              className="suggestion-item"
            >
              <span className="city-name">{city.name}</span>
              <span className="country-code">{city.country}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
