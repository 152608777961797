// src/components/weather/HourlyForecast.jsx
import React from "react";
import { useWeather } from "../../context/WeatherContext";
import "./HourlyForecast.css";

const HourlyForecast = () => {
  const { forecast, convertTemperature } = useWeather();

  if (!forecast) return null;

  // Get next 24 hours of forecast data
  const hourlyData = forecast.list.slice(0, 8); // OpenWeather provides 3-hour intervals

  const formatHour = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleTimeString("en-US", {
      hour: "numeric",
      hour12: true,
    });
  };

  return (
    <div className="hourly-forecast">
      <h3>Today's Forecast</h3>
      <div className="hourly-forecast__container">
        {hourlyData.map((hour, index) => (
          <div key={hour.dt} className="forecast-item">
            <span className="time">
              {index === 0 ? "Now" : formatHour(hour.dt)}
            </span>

            <img
              src={`http://openweathermap.org/img/wn/${hour.weather[0].icon}.png`}
              alt={hour.weather[0].description}
              className="weather-icon"
            />

            <span className="temp">
              {Math.round(convertTemperature(hour.main.temp))}°
            </span>

            <span className="wind">{Math.round(hour.wind.speed)} m/s</span>

            <span className="humidity">{hour.main.humidity}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HourlyForecast;
