// src/components/pages/Home.jsx
import React from "react";
import { useWeather } from "../../context/WeatherContext";
import SearchBar from "../weather/SearchBar";
import WeatherOverview from "../weather/WeatherOverview";
import SunMoonInfo from "../weather/SunMoonInfo";
import HourlyForecast from "../weather/HourlyForecast";
import DailyForecast from "../weather/DailyForecast";
import "./Home.css";

const Home = () => {
  const { loading, error, currentWeather } = useWeather();

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner" />
        <p>Loading weather data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
        <button
          onClick={() => window.location.reload()}
          className="retry-button"
        >
          Retry
        </button>
      </div>
    );
  }

  if (!currentWeather) {
    return (
      <div className="error-container">
        <p>No weather data available. Please try searching for a city.</p>
      </div>
    );
  }

  return (
    <div className="home">
      <SearchBar />

      <div className="weather-grid">
        <div className="weather-grid__main">
          <WeatherOverview />
          <div className="weather-description2">
            <h1> Weather Press</h1>
            <p>
              Get real-time, accurate forecasts and detailed weather insights to
              plan your day with confidence—no matter where you are.!
            </p>
          </div>
        </div>

        <div className="weather-grid__sidebar">
          <SunMoonInfo />
        </div>
      </div>

      <HourlyForecast />
      <DailyForecast />
    </div>
  );
};

export default Home;
