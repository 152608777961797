// src/components/layout/Header.jsx
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const location = useLocation();

  const navigationLinks = [
    { path: "/", label: "Weather" },
    { path: "/about", label: "About" },
    { path: "/contact", label: "Contact" },
    { path: "/terms", label: "Terms" },
    { path: "/privacy", label: "Privacy" },
  ];

  return (
    <header className="header">
      <div className="header__content">
        <Link to="/" className="logo">
          <img src="/weather-press.png" alt="Weather Press Logo" />
        </Link>

        <nav className="navigation">
          {navigationLinks.map((link) => (
            <Link
              key={link.path}
              to={link.path}
              className={`nav-link ${
                location.pathname === link.path ? "active" : ""
              }`}
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default Header;
