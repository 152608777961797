// src/App.jsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { WeatherProvider } from "./context/WeatherContext";
import { useWeather } from "./context/WeatherContext";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";
import { getWeatherBackground } from "./services/weatherService";
import TawkMessenger from "./components/TawkMessenger";
import "./App.css";

const AppContent = () => {
  const { currentWeather } = useWeather();

  // Determine if it's daytime
  const isDay = () => {
    if (!currentWeather) return true;
    const now = new Date().getTime() / 1000;
    return now > currentWeather.sys.sunrise && now < currentWeather.sys.sunset;
  };

  // Get background based on weather
  const background = currentWeather
    ? getWeatherBackground(currentWeather.weather[0].icon.slice(0, 2), isDay())
    : getWeatherBackground("01", true); // Default to clear day if no weather data

  return (
    <div
      className="app"
      style={{
        backgroundImage: `url(${background})`,
        color: isDay() ? "#000" : "#fff",
      }}
    >
      <div className="app-overlay">
        <Header />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </main>
        <TawkMessenger />
        <Footer />
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <WeatherProvider>
        <AppContent />
      </WeatherProvider>
    </Router>
  );
};

export default App;
