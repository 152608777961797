// src/components/pages/Privacy.jsx
import React from "react";
import "./Pages.css";

const Privacy = () => {
  return (
    <div className="page-container">
      <div className="page-content">
        <h1>Privacy Policy</h1>

        <section className="content-section">
          <h2>Introduction</h2>
          <p>
            At Weather Press, we take your privacy seriously. This Privacy
            Policy describes how we collect, use, and handle your personal
            information when you use our services.
          </p>
        </section>

        <section className="content-section">
          <h2>Information We Collect</h2>
          <h3>1. Location Data</h3>
          <p>
            We collect location data to provide accurate weather information for
            your area. This includes:
          </p>
          <ul>
            <li>GPS coordinates (with your permission)</li>
            <li>IP-based location data</li>
            <li>Cities you search for</li>
          </ul>

          <h3>2. Usage Data</h3>
          <p>
            We collect information about how you use Weather Press including:
          </p>
          <ul>
            <li>Features you use</li>
            <li>Pages you visit</li>
            <li>Search queries</li>
            <li>Device information</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>How We Use Your Information</h2>
          <p>We use the collected information to:</p>
          <ul>
            <li>Provide accurate weather forecasts</li>
            <li>Improve our services</li>
            <li>Customize your experience</li>
            <li>Analyze usage patterns</li>
            <li>Troubleshoot technical issues</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Data Storage</h2>
          <p>
            We store your data securely using industry-standard encryption.
            Location preferences are stored locally on your device when
            possible.
          </p>
        </section>

        <section className="content-section">
          <h2>Data Sharing</h2>
          <p>
            We do not sell your personal information. We may share anonymous,
            aggregated data with our weather data providers to improve service
            accuracy.
          </p>
        </section>

        <section className="content-section">
          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
            <li>Opt-out of data collection</li>
            <li>Export your data</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at info@weather-press.com.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
