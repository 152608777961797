// src/services/weatherService.js
const API_KEY = process.env.REACT_APP_OPENWEATHER_API_KEY;
const BASE_URL = "https://api.openweathermap.org/data/2.5";
const GEO_URL = "https://api.openweathermap.org/geo/1.0";

export const weatherService = {
  async getCurrentWeather(lat, lon) {
    try {
      const response = await fetch(
        `${BASE_URL}/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
      );

      if (!response.ok) {
        throw new Error("Weather data fetch failed");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching current weather:", error);
      throw error;
    }
  },

  async getForecast(lat, lon) {
    try {
      const response = await fetch(
        `${BASE_URL}/forecast?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=metric`
      );

      if (!response.ok) {
        throw new Error("Forecast data fetch failed");
      }

      return await response.json();
    } catch (error) {
      console.error("Error fetching forecast:", error);
      throw error;
    }
  },

  async searchCity(query) {
    try {
      const response = await fetch(
        `${GEO_URL}/direct?q=${encodeURIComponent(
          query
        )}&limit=5&appid=${API_KEY}`
      );

      if (!response.ok) {
        throw new Error("City search failed");
      }

      return await response.json();
    } catch (error) {
      console.error("Error searching city:", error);
      throw error;
    }
  },

  calculateUVIndex(lat, lon, dt) {
    const hour = new Date(dt * 1000).getHours();
    const absLat = Math.abs(lat);

    if (hour < 6 || hour > 18) return 0;

    let baseUV = 10 - absLat / 9;
    let timeMultiplier = 1 - Math.abs(hour - 12) / 6;

    return Math.round(baseUV * timeMultiplier);
  },

  calculateMoonPhase(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const c = Math.floor((year - 2000) * 365.25);
    const e = Math.floor(30.6 * month);
    const jd = c + e + day - 694039.09;
    const phase = jd / 29.53;

    return phase - Math.floor(phase);
  },
};

export const getWeatherBackground = (weatherCode, isDay) => {
  const timeOfDay = isDay ? "day" : "night";
  const weatherMap = {
    "01": "clear",
    "02": "few-clouds",
    "03": "scattered-clouds",
    "04": "broken-clouds",
    "09": "shower-rain",
    10: "rain",
    11: "thunderstorm",
    13: "snow",
    50: "mist",
  };

  const condition = weatherMap[weatherCode] || "clear";
  return `/assets/images/backgrounds/${condition}-${timeOfDay}.jpg`;
};
