// src/components/pages/Contact.jsx
import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./Pages.css";

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  });

  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    error: null,
  });

  // Initialize EmailJS when component mounts
  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ submitting: true, submitted: false, error: null });

    try {
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      console.log("EmailJS Response:", result);

      if (result.text === "OK") {
        setStatus({
          submitting: false,
          submitted: true,
          error: null,
        });

        setFormData({
          user_name: "",
          user_email: "",
          subject: "",
          message: "",
        });
      }
    } catch (error) {
      console.error("Email send failed:", error);
      setStatus({
        submitting: false,
        submitted: false,
        error: "Failed to send message. Please try again.",
      });
    }
  };

  if (status.submitted) {
    return (
      <div className="page-container">
        <div className="page-content">
          <div className="success-message">
            <h3>Thank you for your message!</h3>
            <p>We'll get back to you as soon as possible.</p>
            <button
              className="button"
              onClick={() =>
                setStatus({ submitted: false, submitting: false, error: null })
              }
            >
              Send another message
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page-container">
      <div className="page-content">
        <h1>Contact Us</h1>

        <section className="content-section">
          <p>
            Have questions or suggestions? We'd love to hear from you. Send us a
            message and we'll respond as soon as possible.
          </p>
        </section>

        <section className="content-section">
          <form ref={form} onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <label htmlFor="user_name">Name *</label>
              <input
                type="text"
                id="user_name"
                name="user_name"
                value={formData.user_name}
                onChange={handleChange}
                required
                className={status.error ? "error" : ""}
                placeholder="Your name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="user_email">Email *</label>
              <input
                type="email"
                id="user_email"
                name="user_email"
                value={formData.user_email}
                onChange={handleChange}
                required
                className={status.error ? "error" : ""}
                placeholder="your.email@example.com"
              />
            </div>

            <div className="form-group">
              <label htmlFor="subject">Subject *</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className={status.error ? "error" : ""}
                placeholder="What is this about?"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message *</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
                className={status.error ? "error" : ""}
                placeholder="Your message here..."
              />
            </div>

            {status.error && (
              <div className="error-message">{status.error}</div>
            )}

            <button
              type="submit"
              className="button"
              disabled={status.submitting}
            >
              {status.submitting ? "Sending..." : "Send Message"}
            </button>
          </form>
        </section>

        <section className="content-section">
          <h2>Contact Information</h2>
          <div className="contact-info">
            <p>Email: info@weather-press.com</p>
            <p>Phone: +1 (310) 555-7890, +44 20 7946 0123, +61 3 9123 4567 </p>
            <p>
              Address: <br /> USA: 1234 Sunset Blvd, Los Angeles, CA 90028, USA,{" "}
              <br />
              UK: 56 Oxford Street, London W1D 1BL, UK, <br />
              Australia: 89 Queen Street, Melbourne, VIC 3000, Australia
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
