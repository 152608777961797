// src/components/pages/About.jsx
import React from "react";
import "./Pages.css";

const About = () => {
  return (
    <div className="page-container">
      <div className="page-content">
        <h1>About Weather Press</h1>

        <section className="content-section">
          <h2>Our Mission</h2>
          <p>
            Weather Press is designed to provide accurate, real-time weather
            information in a beautiful and intuitive interface. We believe that
            checking the weather should be both informative and visually
            pleasant.
          </p>
        </section>

        <section className="content-section">
          <h2>Features</h2>
          <ul>
            <li>Real-time weather data from reliable sources</li>
            <li>Detailed hourly and daily forecasts</li>
            <li>Sun and moon information</li>
            <li>Dynamic backgrounds that reflect current weather conditions</li>
            <li>Location-based automatic weather detection</li>
            <li>Search functionality for worldwide weather information</li>
          </ul>
        </section>

        <section className="content-section">
          <h2>Data Sources</h2>
          <p>
            We use OpenWeather API to provide accurate weather data. Additional
            calculations and atmospheric data are processed using our
            proprietary algorithms to ensure the most accurate information
            possible.
          </p>
        </section>
      </div>
    </div>
  );
};

export default About;
